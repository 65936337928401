<template>
  <div class="mt-10 p-4 flex flex-wrap justify-center">
    <div 
      class="ml-4 text-2x text-yellow-600"
      v-for="(pokemon, idx) in filteredPokemon"
      :key="idx"
    >
    <router-link :to="{name: 'About', params: { slug: `${urlIdLookup[pokemon.name]}` , title: `${pokemon.name}`}}">
        {{ pokemon.name[0].toUpperCase() + pokemon.name.slice(1) }}
    </router-link>
    </div>
  </div>
  <div class="w-full flex justify-center">
    <input type="text" placeholder="Pokémon name"
    class="mt-10 p-2 border border-blue-500"
    :value="text"
    @input="e => text = e.target.value"/>
  </div>
</template>

<script>
// @ is an alias to /src
import {computed, reactive, toRefs} from 'vue'

export default {
  name: 'Home',
  setup() {
    const state = reactive({
      pokemons: [],
      urlIdLookup: {},
      text: "",
      filteredPokemon: computed(() => updatePokemon()),
      offset: 0
    })

    function updatePokemon() {
      if(!state.text) {
        return [];
      }

      return state.pokemons.filter((pokemon) => 
        pokemon.name.toLowerCase().includes(state.text.toLowerCase())
      )
    }

    fetch(`https://pokeapi.co/api/v2/pokemon?offset=${state.offset}`)
      .then((res) => res.json())
      .then((data) => {
        state.pokemons = data.results
        state.urlIdLookup = data.results.reduce((acc, cur, idx) => 
          acc = {...acc, [cur.name]:idx+1+state.offset}
        , {})
      })

    return {...toRefs(state)}
  }
}
</script>
