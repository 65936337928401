<template>
  <div class="p-14">
    <router-link class="flex justify-center text-4xl text-blue-800" to="/">Paulké Dex</router-link>
  </div>
  <router-view/>
</template>

<style>
body {
  background-color: rgb(250, 250, 250);
}
</style>
